import { cn } from "~/lib/utils";
import { iconMap } from "./icons";
import { SelectSearchable } from "./select-searchable";

export function SelectIcon(props: {
  value: string;
  className?: string;
  onChange: (value: string) => void;
}) {
  const { value, onChange, className } = props;
  return (
    <SelectSearchable
      className={cn("h-7", className)}
      items={Array.from(iconMap)
        .filter((icon) => icon[0] !== "")
        .map(([key, value]) => {
          const Icon = key ? iconMap.get(key) : undefined;
          return {
            value: key,
            label: Icon ? (
              <div className="flex items-center">
                <Icon className="text-primary-foreground size-4 mr-2" />
                {key}
              </div>
            ) : (
              key
            ),
          };
        })}
      empty={"No icons found"}
      value={value}
      onChange={onChange}
      placeholder="Select icon"
    />
  );
}
